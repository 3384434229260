'use client';

import * as React from 'react';

type AnimatedContextValue = {
  rect: DOMRect | null;
  activeIndicatorRect: DOMRect | null;
  hoverIndicatorRect: DOMRect | null;
};

type AnimatedContextUpdaterValue = {
  setRect: React.Dispatch<React.SetStateAction<DOMRect | null>>;
  setActiveIndicatorRect: React.Dispatch<React.SetStateAction<DOMRect | null>>;
  setHoverIndicatorRect: React.Dispatch<React.SetStateAction<DOMRect | null>>;
};

export const AnimatedContext = React.createContext<AnimatedContextValue>({
  rect: null,
  activeIndicatorRect: null,
  hoverIndicatorRect: null,
});

export const AnimatedContextUpdater = React.createContext<AnimatedContextUpdaterValue>({
  setRect: () => {},
  setActiveIndicatorRect: () => {},
  setHoverIndicatorRect: () => {},
});

export default function useAnimated() {
  const context = React.useContext(AnimatedContext);

  if (!context) {
    throw new Error('useAnimation must be used within a AnimatedProvider');
  }

  return context;
}

export function useAnimatedUpdater() {
  const context = React.useContext(AnimatedContextUpdater);

  if (!context) {
    throw new Error('useAnimatedUpdater must be used within a AnimatedProvider');
  }

  return context;
}

export function AnimatedProvider({ children }: { children: React.ReactNode }) {
  const [rect, setRect] = React.useState<DOMRect | null>(null);
  const [activeIndicatorRect, setActiveIndicatorRect] = React.useState<DOMRect | null>(null);
  const [hoverIndicatorRect, setHoverIndicatorRect] = React.useState<DOMRect | null>(null);

  const contextValue = React.useMemo(
    () => ({ rect, activeIndicatorRect, hoverIndicatorRect }),
    [rect, activeIndicatorRect, hoverIndicatorRect]
  );

  const updaterValue = React.useMemo(
    () => ({ setRect, setActiveIndicatorRect, setHoverIndicatorRect }),
    []
  );

  return (
    <AnimatedContext.Provider value={contextValue}>
      <AnimatedContextUpdater.Provider value={updaterValue}>
        {children}
      </AnimatedContextUpdater.Provider>
    </AnimatedContext.Provider>
  );
}
