'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { AnimatedContextUpdater } from '@haaretz/s-ui-providers/AnimatedProvider';
import { useDescendant } from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';
import s9 from 'style9';

import { TabsDescendantContext } from '../Tabs';

import { TabsContext } from './providers/TabsProvider';
import TabPrimitive from './TabPrimitive';

import type { TabProps } from './TabPrimitive';
const c = s9.create({
  tab: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'inline-flex',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
    minWidth: 0,
    paddingTop: space(1),
    ...typesetter(-1),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),

    ':disabled': {
      pointerEvents: 'none',
      opacity: 0.25,
    },
  },
  tabHomepage: {
    height: space(10),
    paddingBottom: space(2),
    ...merge(
      mq({
        from: 's',
        value: {
          height: space(12),
        },
      })
    ),
  },
  tabActive: {
    color: color('primary1000'),
    fontWeight: '700',
  },
});

export default function Tab({
  children,
  disabled,
  inlineStyle,
  onClick,
  styleExtend = [],
}: TabProps) {
  const { setActiveIndicatorRect, setHoverIndicatorRect } =
    React.useContext(AnimatedContextUpdater);
  const { variant, activeTabRef, selectedIndex } = React.useContext(TabsContext);
  const tabRef = React.useRef<HTMLButtonElement>(null);
  const index = useDescendant(tabRef.current, TabsDescendantContext);
  const isSelected = index === selectedIndex;
  const onMouseEnter = () => {
    if (tabRef.current) {
      setHoverIndicatorRect(tabRef.current.getBoundingClientRect());
    }
  };
  const onMouseLeave = () => {
    if (activeTabRef) {
      // setActiveIndicatorRect(activeTabRef.getBoundingClientRect());
      setHoverIndicatorRect(activeTabRef.getBoundingClientRect());
    }
  };

  React.useEffect(() => {
    const currentTabRef = tabRef.current;

    if (isSelected && currentTabRef) {
      setActiveIndicatorRect(currentTabRef.getBoundingClientRect());
    }

    function updateIndicatorRect() {
      if (isSelected && currentTabRef) {
        setActiveIndicatorRect(currentTabRef.getBoundingClientRect());
        setHoverIndicatorRect(currentTabRef.getBoundingClientRect());
      }
    }

    const resizeObserverTab = new ResizeObserver(updateIndicatorRect);

    if (currentTabRef) {
      resizeObserverTab.observe(currentTabRef);
    }

    window.addEventListener('resize', updateIndicatorRect);

    return () => {
      if (currentTabRef) {
        resizeObserverTab.unobserve(currentTabRef);
      }
      window.removeEventListener('resize', updateIndicatorRect);
    };
  }, [isSelected, setActiveIndicatorRect, setHoverIndicatorRect]);

  return (
    <TabPrimitive
      styleExtend={[c.tab, variant === 'homepage' && c.tabHomepage, ...styleExtend]}
      activeTabStyleExtend={[c.tabActive]}
      ref={tabRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      inlineStyle={inlineStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </TabPrimitive>
  );
}
