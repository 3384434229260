'use client';

import * as React from 'react';

type TabsContextValue = {
  selectedIndex: number | null;
  rootId: string;
  activeTabRef: HTMLButtonElement | null;
  variant: 'default' | 'homepage' | 'recipe';
  checkboxCheckedState: Record<number, boolean>;
};

type TabsUpdaterContextValue = {
  setActiveTabRef: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onSelectTab: (index: number) => void;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  toggleCheckbox: (index: number) => void;
};

const defaultTabsContextValue: TabsContextValue = {
  selectedIndex: 0,
  rootId: '',
  activeTabRef: null,
  variant: 'default',
  checkboxCheckedState: {},
};

const defaultTabsUpdaterContextValue: TabsUpdaterContextValue = {
  onSelectTab: () => {},
  setSelectedIndex: () => {},
  setActiveTabRef: () => {},
  toggleCheckbox: () => {},
};

export const TabsContext = React.createContext<TabsContextValue>(defaultTabsContextValue);
export const TabsUpdaterContext = React.createContext<TabsUpdaterContextValue>(
  defaultTabsUpdaterContextValue
);

export default function TabsProvider({
  children,
  defaultTabIndex = 0,
  variant = 'default',
}: {
  children: React.ReactNode;
  defaultTabIndex?: number;
  variant: 'default' | 'homepage' | 'recipe';
}) {
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const [activeTabRef, setActiveTabRef] = React.useState<HTMLButtonElement | null>(null);
  /**
   * checkboxCheckedState is a record of booleans that represent the checked state of each checkbox, used on the recipe variant
   */
  const [checkboxCheckedState, setCheckedState] = React.useState<Record<number, boolean>>({});

  React.useEffect(() => {
    if (selectedIndex === null) {
      setSelectedIndex(defaultTabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rootId = React.useId();

  const onSelectTab = React.useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );
  /**
   * toggleCheckbox is a function that toggles the checked state of a checkbox
   * @param index - the index of the checkbox in the checkboxes array
   */
  const toggleCheckbox = React.useCallback((index: number) => {
    setCheckedState(prev => ({ ...prev, [index]: !prev[index] }));
  }, []);

  return (
    <TabsContext.Provider
      value={{
        selectedIndex,
        rootId,
        activeTabRef,
        variant,
        checkboxCheckedState,
      }}
    >
      <TabsUpdaterContext.Provider
        value={{
          setSelectedIndex,
          onSelectTab,
          setActiveTabRef,
          toggleCheckbox,
        }}
      >
        {children}
      </TabsUpdaterContext.Provider>
    </TabsContext.Provider>
  );
}
